import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`The first workout of the 3rd Annual Pre Open starts today!  Each
Pre Open WOD will be the class wod so there’s no reason not to compete!
 RX, Scaled, and Masters over 50 Divisions.  Email Daniel in order to
sign up and be added to the google drive in order to view the
standings.`}</em></strong></p>
    <p><strong parentName="p">{`Pre Open WOD1 (A&B)`}</strong></p>
    <p>{`5:00 to establish 1RM clean (power or full)`}</p>
    <p>{`then at the 5:00 mark,`}</p>
    <p>{`3 rounds for time of:`}</p>
    <p>{`20-Calorie Assault Bike`}</p>
    <p>{`15-Burpee Box Jump Overs (24/20)`}</p>
    <p><em parentName="p">{`*`}{`-Scaled division: Sttep Overs are allowed`}</em></p>
    <p><em parentName="p">{`*`}{`-Masters division: 15-calorie bike, step overs are allowed`}</em></p>
    <p><strong parentName="p">{`*`}{`Saturday, January 13th is Cardinal Fitness Day at the Yum!.  The
Cards play Virginia Tech at 4:00pm.  With the following link you can get
discounted tickets as a CrossFit the Ville member!  After the game there
will be a CrossFit wod on the court along with several other workout
sessions.  Lets get a big group together and represent The Ville at the
Yum center!  Family is invited as well.`}</strong></p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://fevo.me/fitday"
        }}>{`https://fevo.me/fitday`}</a></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start January 7th.  Email Eric for
more info: Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      